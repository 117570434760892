import React from 'react'

export const Marquee = () => {
  return (
    <div className="marquee">
    <div className="track">
      <div className="content wavy-underline"> 
       ~ currently open to new opportunities  ~   currently open to new opportunities ~  currently open to new opportunities~ currently open to new opportunities  ~
      </div>
    </div>
  </div>
  )
}
