import React from 'react'

export const Navigation = () => {
  return (
    <div className="container">
        <a href="/">
            <img className="logo" src="/jws.png" alt="logo"/>
        </a>
    </div>
  )
}
